import React, { useRef } from 'react';
import Link from 'gatsby-link';
import styled, { css } from 'styled-components';
import Layout from '../components/Layout';
import Squiggle from '../components/Squiggle';
import TitleAndTheme from '../components/TitleAndTheme';
import Greeting from '../components/Greeting';
import Text from '../components/Text';
import AssetWithCaption from '../components/AssetWithCaption';
import LargeTitle from '../components/LargeTitle';
import DrawingSurface from '../components/DrawingSurface';
import Grid, { GridThreeAcross } from '../components/Grid';

const IndexPage = () => {
  const squiggleParentRef = useRef();

  return (
    <Layout>
      <TitleAndTheme
        title="Edwin Morris, product designer and front-end developer"
        themeClass="theme--index"
      />

      <TopSection ref={squiggleParentRef}>
        <Text noTopMargin noBottomMargin position="left_inset_text">
          <SquiggleContainer>
            <Greeting />
            <Squiggle />
          </SquiggleContainer>
        </Text>
        <DrawingSurface eventParentRef={squiggleParentRef} size="60" />
      </TopSection>

      <IndexItem id="datadogdashboards" noTopMargin>
        <LargeTitle>I led design on Dashboards at Datadog</LargeTitle>

        <Text noTopMargin position="left_inset_text">
          <p>
            Dashboards are Datadog’s most used feature. I led design on a{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.datadoghq.com/blog/datadog-dashboards/"
            >
              new dashboard layout system
            </a>{' '}
            that overhauled how users create dashboards and how dashboards
            respond to different browser widths. The release contained dozens of
            quality-of-life improvements over the previous dashboard solutions
            and has enabled users to create complex layouts in a fraction of the
            time.
          </p>
          <p>
            Most of our competitors use React-Grid-Layout but we built a layout
            system from the ground up that enables our dashboards to have
            complex grouping, responsive behavior, and usability improvements.{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/watch?v=1cRLvJmCnj8"
            >
              You can watch me talk more about it here
            </a>
            .
          </p>
        </Text>
        <Grid verticalAlign="center">
          <AssetWithCaption
            position="left_half"
            video="datadog/squeeze_small"
            browserChrome="true"
            width="2400"
            height="1500"
          />
          <AssetWithCaption
            position="right_half"
            video="datadog/group_small"
            browserChrome="true"
            width="2400"
            height="1500"
          />
        </Grid>
        <Text noBottomMargin position="left_inset_text">
          <p>
            Since working on Dashboards, I’ve also worked on Monitors and helped
            to define Datadog’s AI strategy. I’m currently responsible for all
            design in the Service Management organization, which includes
            emerging products like Workflows, App Builder, On-Call, Incidents,
            Case Management, and more.
          </p>
        </Text>
      </IndexItem>

      <IndexItem id="datadogdashboards" noTopMargin>
        <LargeTitle>Can you land the ship?</LargeTitle>

        <Text noTopMargin position="left_inset_text">
          <OutlineTextAdjacent>
            “Lander” is an addictive web-based game I created in 2023. I wrote
            it{' '}
            <a
              rel="noopener noreferrer"
              href="https://github.com/ehmorris/lunar-lander"
              target="_blank"
            >
              from scratch
            </a>{' '}
            in plain JavaScript with no dependencies. It reached{' '}
            <a
              rel="noopener noreferrer"
              href="https://news.ycombinator.com/item?id=35032506"
              target="_blank"
            >
              #1 on Hacker News
            </a>
            , was posted on{' '}
            <a
              rel="noopener noreferrer"
              href="https://kottke.org/23/03/0041847-oh-my-god-this-in-browser"
              target="_blank"
            >
              Kottke
            </a>
            ,{' '}
            <a
              rel="noopener noreferrer"
              href="https://x.com/search?q=ehmorris.com%2Flander&src=recent_search_click&f=live"
              target="_blank"
            >
              trends on Twitter
            </a>{' '}
            once in a while, and continues to be played by{' '}
            <a
              rel="noopener noreferrer"
              href="https://p.datadoghq.com/sb/u83i2ef1yychlfsw-3ffb632e8cb2d1923778431e8ade206f"
              target="_blank"
            >
              more than 20,000
            </a>{' '}
            people every month.
          </OutlineTextAdjacent>
          <OutlineExternalLink
            rel="noopener noreferrer"
            href="https://ehmorris.com/lander/"
            target="_blank"
          >
            You should try playing it
          </OutlineExternalLink>
          <OutlineTextAdjacent>
            . You can also watch me give a{' '}
            <a
              rel="noopener noreferrer"
              href="https://www.youtube.com/watch?v=_0ZsbveR-sc"
              target="_blank"
            >
              five minute talk about it
            </a>
            .
          </OutlineTextAdjacent>
        </Text>
        <Grid verticalAlign="center">
          <AssetWithCaption
            position="inset_center"
            video="lander/lander_small"
            width="3452"
            height="2160"
            browserChrome="true"
            hasSound="true"
          />
        </Grid>
      </IndexItem>

      <IndexItem id="glitchoverview" noTopMargin>
        <LargeTitle>At Glitch I designed “Boosted Apps”</LargeTitle>

        <Text noTopMargin position="left_inset_text">
          <p>
            Glitch (
            <a
              rel="noopener noreferrer"
              href="https://glitch.com"
              target="_blank"
            >
              glitch.com
            </a>
            ) is a fun, approachable tool for making websites. I owned the
            design and front-end code for the{' '}
            <a
              rel="noopener noreferrer"
              href="https://glitch.com/edit"
              target="_blank"
            >
              IDE
            </a>
            , which is where users spend most of their time. I also designed
            product-wide features like the navigation.
          </p>
          <p>
            The last big project I worked on was designing the paid product:
            Boosted Apps.{' '}
            <a
              rel="noopener noreferrer"
              href="https://dev.to/glitch/boosted-apps-glitch-apps-with-more-power-26bk"
              target="_blank"
            >
              You can read about it here
            </a>
            . I worked on everything: the UX, branding, and even the marketing
            material. Glitch has since been acquired by Fastly.
          </p>
        </Text>

        <Grid verticalAlign="center">
          <AssetWithCaption
            position="inset_center"
            video="glitchgeneral/prettier_small"
            browserChrome="true"
            width="1776"
            height="1080"
          >
            The Glitch editor. One{' '}
            <a
              rel="noopener noreferrer"
              href="https://www.youtube.com/watch?v=_NfjmYKMwwI&t=117s"
              target="_blank"
            >
              much-loved feature
            </a>{' '}
            is that formatting your code covers the page in sparkles.
          </AssetWithCaption>
          <AssetWithCaption
            position="left_half"
            video="glitchgeneral/drawterfall_extra_small"
            browserChrome="true"
            width="1600"
            height="1260"
          >
            I made a few sample apps. This is a web version of “Exquisite
            corpse”.
          </AssetWithCaption>
          <AssetWithCaption
            position="right_half"
            video="glitchgeneral/drawing_extra_small"
            browserChrome="true"
            width="1600"
            height="1260"
          >
            This is a basic drawing app.
          </AssetWithCaption>
          <AssetWithCaption
            position="center_tiny_image"
            video="glitchgeneral/diamond"
            disableZoom="true"
            hideControls="true"
            width="500"
            height="750"
          />
        </Grid>
      </IndexItem>

      <IndexItem id="geniusoverview">
        <LargeTitle>
          I was the Product Design Lead at{' '}
          <Highlight to="/geniusoverview/">Genius</Highlight>
        </LargeTitle>

        <Text noTopMargin position="left_inset_text">
          <OutlineTextAdjacent>
            Genius is the world’s largest lyrics platform, and more. I worked
            there as a Product Design Lead and Front-end Developer, doing work
            as diverse as designing prototypes for decks, to implementing the
            song page on mobile. I even wrote the styleguide for how CSS and
            markup should be written. Genius has since been acquired by
            MediaLab.{' '}
          </OutlineTextAdjacent>
          <OutlineLink to="/geniusoverview/">
            See more of my work here
          </OutlineLink>
          <OutlineTextAdjacent>.</OutlineTextAdjacent>
        </Text>

        <Grid>
          <AssetWithCaption
            position="left_half"
            image="overview/overview_desktop_tate"
            width="2880"
            height="1732"
            browserChrome="true"
          />

          <AssetWithCaption
            position="right_half"
            image="overview/overview_desktop_video"
            width="2880"
            height="1732"
            browserChrome="true"
          />

          <AssetWithCaption
            position="offset_triplet_1"
            image="overview/overview_mobile_song"
            width="750"
            height="1334"
            phoneChrome="true"
          />

          <AssetWithCaption
            position="offset_triplet_2"
            image="overview/overview_mobile_annotation"
            width="750"
            height="1334"
            phoneChrome="true"
          />

          <AssetWithCaption
            position="offset_triplet_3"
            image="overview/overview_mobile_search"
            width="750"
            height="1334"
            phoneChrome="true"
          />
        </Grid>
      </IndexItem>

      <IndexItem id="videonotes">
        <LargeTitle>
          <Highlight to="/videonotes/">LTC Video Notes</Highlight> is a
          specialized note-taking tool for video producers
        </LargeTitle>
        <Text noTopMargin position="left_inset_text">
          <OutlineTextAdjacent>
            I made it for Genius’ video team.{' '}
          </OutlineTextAdjacent>
          <OutlineLink to="/videonotes/">
            Read about the design and development process, and some of the more
            interesting implementation details
          </OutlineLink>
          <OutlineTextAdjacent>.</OutlineTextAdjacent>
        </Text>

        <Grid>
          <AssetWithCaption
            position="left_text"
            image="videonotes/videonotes_producer_view_3"
            type="jpg"
            width="2500"
            height="1875"
          />

          <AssetWithCaption
            position="right_text"
            image="videonotes/videonotes_interviewer_view_1"
            type="jpg"
            width="2500"
            height="1875"
          />

          <AssetWithCaption
            position="full"
            video="videonotes/videonotes_header_white_narrow"
            width="1920"
            height="1152"
            disableZoom="true"
          />
        </Grid>
      </IndexItem>

      <IndexItem id="keepsakes">
        <LargeTitle>
          <Highlight to="/keepsakes/">Keepsakes</Highlight> works with data you
          already have
        </LargeTitle>
        <Text noTopMargin position="left_inset_text">
          <OutlineTextAdjacent>
            This was an automatic journal I created for my degree project in
            college. The interface uses 3D layering and mouse gestures to reduce
            the amount of on-screen UI.{' '}
          </OutlineTextAdjacent>
          <OutlineLink to="/keepsakes/">
            Read a little more about how it works and watch some demos
          </OutlineLink>
          <OutlineTextAdjacent>.</OutlineTextAdjacent>
        </Text>

        <Grid>
          <AssetWithCaption
            position="left_half"
            image="keepsakes/panel_date_list_1"
            width="2880"
            height="1800"
            browserChrome
          />

          <AssetWithCaption
            position="right_half"
            image="keepsakes/overlay_places_list"
            width="2880"
            height="1800"
            browserChrome
          />

          <AssetWithCaption
            position="inset_center"
            video="keepsakes/cursors_gl_wide_fast"
            width="1600"
            height="800"
            browserChrome
          />
        </Grid>
      </IndexItem>

      <IndexItem id="songstories">
        <LargeTitle>
          <Highlight to="/songstories/">Song Stories</Highlight> almost replaced
          the Genius lyrics page
        </LargeTitle>
        <Text noTopMargin position="left_inset_text">
          <OutlineTextAdjacent>
            This was a nearly year-long project at Genius that went through two
            major iterations. I used Origami to create elaborate prototypes fed
            by JSON.{' '}
          </OutlineTextAdjacent>
          <OutlineLink to="/songstories/">
            Read more about the background for this project, and see both
            versions
          </OutlineLink>
          <OutlineTextAdjacent>.</OutlineTextAdjacent>
        </Text>

        <GridThreeAcross noTopMargin>
          <AssetWithCaption
            position="left_third"
            video="songstories2/key_lyric_small"
            width="750"
            height="1334"
            phoneChrome="true"
          />

          <AssetWithCaption
            position="center_third"
            video="songstories2/poll_small"
            width="728"
            height="1294"
            phoneChrome="true"
          />

          <AssetWithCaption
            position="right_third"
            video="songstories2/cta_text_rotate_small"
            width="750"
            height="1334"
            phoneChrome="true"
          />
        </GridThreeAcross>
      </IndexItem>

      <IndexItem id="origamihue">
        <LargeTitle>
          This <Highlight to="/origamihue/">Origami prototype</Highlight> works
          like a real app
        </LargeTitle>
        <Text noTopMargin position="left_inset_text">
          <OutlineTextAdjacent>
            It uses the “network request” feature, which allows prototypes to
            send and receive data from APIs. This app connects to the Hue API
            and uses a custom proxy server.{' '}
          </OutlineTextAdjacent>
          <OutlineLink to="/origamihue/">
            Read the details on how I did it
          </OutlineLink>
          <OutlineTextAdjacent>.</OutlineTextAdjacent>
        </Text>

        <Grid>
          <AssetWithCaption
            position="inset_center"
            video="origamihue/origamihue_demo_720p"
            width="1280"
            height="720"
          />
        </Grid>
      </IndexItem>

      <IndexItem id="lyriccardgenerator">
        <LargeTitle>
          I made a <Highlight to="/lyriccardgenerator/">WYSIWYG</Highlight>{' '}
          image editor for Genius’ social media team
        </LargeTitle>
        <Text noTopMargin position="left_inset_text">
          <OutlineTextAdjacent>
            It has neat features like warnings for “out of bounds” and pixelated
            images.{' '}
          </OutlineTextAdjacent>
          <OutlineLink to="/lyriccardgenerator/">
            Read more about the problems this solved and how it works
          </OutlineLink>
          <OutlineTextAdjacent>.</OutlineTextAdjacent>
        </Text>

        <Grid>
          <AssetWithCaption
            position="inset_center"
            video="lyriccardgenerator/dragandzoom_small"
            width="1600"
            height="1000"
            browserChrome="true"
          />
        </Grid>
      </IndexItem>
    </Layout>
  );
};

export default IndexPage;

const IndexItem = styled.div`
  margin: calc(var(--vertical-space) * 3) 0;

  ${props =>
    props.noTopMargin &&
    css`
      margin-top: 0;
    `}
`;

const TopSection = styled.div`
  position: relative;
  padding-top: calc(var(--vertical-space) * 2);
  padding-bottom: calc(var(--vertical-space) * 3);
  min-height: 75vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) calc(100% - var(--vertical-space)),
    var(--theme-secondary)
  );
`;

const OutlineTextAdjacent = styled.span`
  position: relative;
  z-index: 1;
`;

const OutlineLink = styled(Link)`
  text-decoration-skip-ink: auto;
  background-color: var(--detail-page-color);
  transition: background-color 0.2s ease;
  border: none;
  border-radius: 100px;
  box-shadow: inset 0px 0px 0px 2px transparent;
  cursor: pointer;
  outline: none;
  padding: 0.3em 0.75em;
  margin: -0.3em -0.75em;
  vertical-align: top;
  -webkit-box-decoration-break: clone;
  z-index: 0;
  position: relative;

  &:hover {
    background-color: var(--detail-page-color-dark);
  }
`;

const OutlineExternalLink = styled.a`
  text-decoration-skip-ink: auto;
  background-color: var(--detail-page-color);
  transition: background-color 0.2s ease;
  border: none;
  border-radius: 100px;
  box-shadow: inset 0px 0px 0px 2px transparent;
  cursor: pointer;
  outline: none;
  padding: 0.3em 0.75em;
  margin: -0.3em -0.75em;
  vertical-align: top;
  -webkit-box-decoration-break: clone;
  z-index: 0;
  position: relative;

  &:hover {
    background-color: var(--detail-page-color-dark);
  }
`;

const Highlight = styled(Link)`
  position: relative;
  display: inline;
  text-decoration: none;
  color: inherit;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.4em;
    border-radius: 100px;
    background-color: var(--detail-page-color);
    z-index: -1;
    transition: background-color 0.2s ease;
  }

  &:hover {
    &::before {
      background-color: var(--detail-page-color-dark);
    }
  }
`;

const SquiggleContainer = styled.div`
  position: relative;
`;
